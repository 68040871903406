import React from "react";
import {Heading, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>
                As environmental consciousness grows, so does the demand for eco-friendly home improvement solutions.
                Painting is no exception. At Simi Valley House Painting, we're committed to offering eco-friendly
                painting solutions that minimize environmental impact without compromising on quality. This post
                explores the benefits of choosing eco-friendly paints for your Simi Valley home and how we can help you
                make sustainable choices.
            </Text>
            <Heading as="h3">Why Choose Eco-Friendly Paints?</Heading>
            <Text>
                Traditional paints contain volatile organic compounds (VOCs) that can harm indoor air quality and pose
                health risks. Eco-friendly paints, on the other hand, have low or no VOCs, making them a healthier
                choice for your home and the planet.
            </Text>
            <Heading as="h3">Benefits of Eco-Friendly Painting Solutions</Heading>
            <List styleType="disc">
                <ListItem>
                    <strong>Improved Indoor Air Quality:</strong> By reducing the levels of harmful VOCs, eco-friendly
                    paints contribute to cleaner indoor air, creating a healthier living environment.
                </ListItem>
                <ListItem>
                    <strong>Reduced Environmental Impact:</strong> Eco-friendly paints are formulated to minimize
                    pollution and waste, contributing to a more sustainable future.
                </ListItem>
                <ListItem>
                    <strong>Durable and High-Quality Finish:</strong> Advances in eco-friendly paint technology ensure
                    that choosing a sustainable option doesn't mean sacrificing durability or aesthetic appeal.
                </ListItem>
                <ListItem>
                    <strong>Wide Range of Colors:</strong> Eco-friendly paints come in a vast array of colors, allowing
                    you to achieve your desired look without compromise.
                </ListItem>
            </List>
            <Text>
                At Simi Valley House Painting, we believe in making environmentally responsible choices that align with
                our customers' values. Our expert team is here to guide you through selecting eco-friendly paints that
                suit your style, budget, and sustainability goals.
            </Text>
            <Text>
                Ready to transform your space with beautiful, eco-friendly colors? Contact us today to learn more about
                our environmentally friendly painting services and how we can help bring your vision to life while
                caring for the environment.
            </Text>
        </Stack>
    );
};
