import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import Careers from "../Pages/Careers";
import React from "react";
import {BlogPost} from "../Layouts/BlogPost";
import DirectoriesOnTheWeb from "../Pages/DirectoriesOnTheWeb";
import {InteriorPainting} from "../Pages/Services/InteriorPainting";
import {ExteriorPainting} from "../Pages/Services/ExteriorPainting";
import {CabinetPainting} from "../Pages/Services/CabinetPainting";
import {ColorConsulting} from "../Pages/Services/ColorConsulting";
import {CommercialPainting} from "../Pages/Services/CommercialPainting";
import {DeckAndFencePainting} from "../Pages/Services/DeckAndFencePainting";
import {StuccoRepairAndPainting} from "../Pages/Services/StuccoRepairAndPainting";
import {PressureWashing} from "../Pages/Services/PressureWashing";

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/interior-painting-simi-valley',
                element: <InteriorPainting/>
            },
            {
                path: '/exterior-painting-simi-valley',
                element: <ExteriorPainting/>
            },
            {
                path: '/cabinet-painting-simi-valley',
                element: <CabinetPainting/>
            },
            {
                path: '/color-consulting-simi-valley',
                element: <ColorConsulting/>
            },
            {
                path: '/commercial-painting-simi-valley',
                element: <CommercialPainting/>
            },
            {
                path: '/deck-and-fence-painting-simi-valley',
                element: <DeckAndFencePainting/>
            },
            {
                path: '/stucco-repair-and-painting-simi-valley',
                element: <StuccoRepairAndPainting/>
            },
            {
                path: '/pressure-washing-simi-valley',
                element: <PressureWashing/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '/directories-on-the-web',
                element: <DirectoriesOnTheWeb/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

