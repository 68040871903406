import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import {SEO} from "../Components/SEO/SEO";
import {Box, Link, List, ListItem} from "@chakra-ui/react";

const urls = [
    "https://www.manta.com/c/m1w1cmj/westminster-spray-foam-insulation-specialist",
    "https://www.hotfrog.com/company/eb65071b37af4815327d5e1de214379e",
    "https://www.n49.com/biz/5788395/westminster-spray-foam-insulation-specialist/",
    "https://co-westminster.cataloxy.us/firms/sprayfoaminsulationwestminster.com.htm",
    "https://www.callupcontact.com/b/businessprofile/Westminster_Spray_Foam_Insulation_Specialist/8854787",
    "https://www.zeemaps.com/map?group=4878059&add=1",
    "http://www.askmap.net/location/6795180/united-states/westminster-spray-foam-insulation-specialist",
    "https://www.yplocal.com/home-garden/westminster-spray-foam-insulation-specialist",
    "https://www.sbnation.com/users/sprayfinsulation",
    "https://www.adlocalpages.com/home-garden/westminster-spray-foam-insulation-specialist",
    "https://www.freelistingusa.com/listings/westminster-spray-foam-insulation-specialist",
    "http://www.travelful.net/location/5347070/united-states/westminster-spray-foam-insulation-specialist",
    "http://www.place123.net/place/westminster-spray-foam-insulation-specialist-.-united-states",
    "https://teleadreson.com/westminster-spray-foam-insulation-specialist,-6/ORAOvzkQA.html",
    "https://www.cgmimm.com/local-business/westminster-spray-foam-insulation-specialist",
    "http://where2go.com/binn/b_search.w2g?function=detail&type=power&listing_no=2125331&_UserReference=7F0000014656DF8108804143E69F65968BC0",
    "https://trueen.com/business/listing/westminster-spray-foam-insulation-specialist/389024",
    "https://smallbusinessusa.com/listing/sprayfinsulation.html",
    "https://parkbench.com/directory/westminster-spray-foam-insulation-specialist",
    "https://www.announceamerica.com/home-and-garden/westminster-spray-foam-insulation-specialist",
    "https://www.youbiz.com/listing/westminster-spray-foam-insulation-specialist.html",
    "https://www.homify.com/professionals/9573257/westminster-spray-foam-insulation-specialist",
    "https://www.earthmom.org/home-services/westminster-spray-foam-insulation-specialist",
    "https://www.linkcentre.com/profile/sprayfinsulation/",
    "https://www.cleansway.com/business/westminster-spray-foam-insulation-specialist",
    "https://www.b2bco.com/sprayfoaminsulationwestminster",
    "https://www.consultsdirect.com/consultant/westminster-spray-foam-insulation-specialist",
    "https://www.finditangeles.com/services/westminster-spray-foam-insulation-specialist",
    "https://flokii.com/businesses/view/118423/westminster-spray-foam-insulation-specialist",
    "https://i.imgur.com/TndpMBc.png",
    "https://www.anibookmark.com/business/westminster-spray-foam-insulation-specialist-bs182877.html",
    "https://www.ilistbusiness.com/listing/westminster-spray-foam-insulation-specialist/",
    "https://serverfault.com/users/1069133/spray-foam-insulation?tab=profile",
    "https://www.preferredprofessionals.com/home-services/westminster-spray-foam-insulation-specialist",
    "https://superuser.com/users/1877703/spray-foam-insulation?tab=profile",
    "https://411freedirectory.com/listing/westminster-spray-foam-insulation-specialist-592507",
    "https://activdirectory.net/listing/westminster-spray-foam-insulation-specialist-804172",
    "https://www.thelululist.com/brown-and-black-owned-home-services/westminster-spray-foam-insulation-specialist",
    "https://directory6.org/listing/westminster-spray-foam-insulation-specialist-646453",
    "https://stackoverflow.com/users/23195089/spray-foam-insulation?tab=profile",
    "https://www.directory2020.com/home-improvement-tools/westminster-spray-foam-insulation-specialist",
    "https://www.moneysaversguide.com/business-services/westminster-spray-foam-insulation-specialist",
    "http://www.click4homeservices.com/home-insulation-service/westminster-spray-foam-insulation-specialist",
    "https://www.ailoq.com/westminster-spray-foam-insulation-specialist",
    "https://bizbookusa.com/listing/westminster-spray-foam-insulation-specialist/",
    "https://askubuntu.com/users/1757879/spray-foam-insulation?tab=profile",
    "https://www.getlisteduae.com/listings/westminster-spray-foam-insulation-specialist",
    "https://mathoverflow.net/users/520094/spray-foam-insulation?tab=profile",
    "https://www.webwiki.com/sprayfoaminsulationwestminster.com",
    "https://cally.com/event/index/fiwc2ca623xnf3cc",
    "https://www.bunnydirectories.com/local-deals/westminster-spray-foam-insulation-specialist",
    "https://flipboard.com/@westminster59ed/westminster-spray-foam-insulation-specialist-54cdlh2fy",
    "https://www.cakeresume.com/me/sprayfinsulation",
    "https://www.ourbizdirectory.com/consultants/westminster-spray-foam-insulation-specialist",
    "http://www.spiritualpool.com/westminster-spray-foam-insulation-specialist",
    "https://www.onmap.ae/home-and-office-services/westminster-spray-foam-insulation-specialist",
    "https://www.digitalbusinessdirectory.online/westminster-spray-foam-insulation-specialist",
    "https://www.bestincom.com/top-level-category/westminster-spray-foam-insulation-specialist",
    "https://stackapps.com/users/121181/spray-foam-insulation?tab=profile",
    "https://www.dennisdemo.com/home-services-1/westminster-spray-foam-insulation-specialist",
    "http://www.countrypwr.com/westminster-spray-foam-insulation-specialist",
    "https://www.osogbo.com/home-services-and-tradesmen/westminster-spray-foam-insulation-specialist",
    "https://www.bpublic.com/home-services/westminster-spray-foam-insulation-specialist",
    "https://local.gocommercially.com/home-services/westminster-spray-foam-insulation-specialist",
    "http://www.247globalbusinesssolutions.com/home-services/westminster-spray-foam-insulation-specialist",
    "https://www.pinterest.com/sprayfinsulation/",
    "https://buynow-us.com/details.php?id=636366&key=61423fedc83d803ab12e6ee810db78f2",
    "https://medium.com/@sprayfinsulation",
    "http://prsync.com/westminster-spray-foam-insulation-specialist/",
    "https://www.tripadvisor.com/Profile/sprayfinsulation",
    "https://thelocal.directory/business-directory/westminster-spray-foam-insulation-specialist/",
    "https://www.quora.com/profile/Westminster-Spray-Foam-Insulation-Specialist",
    "https://www.insertbiz.com/listing/westminster-spray-foam-insulation-specialist/",
    "https://www.localhomeservicepros.com/home-improvement/westminster-spray-foam-insulation-specialist",
    "https://meta.stackexchange.com/users/1453445/spray-foam-insulation?tab=profile",
    "https://www.addonbiz.com/listing/westminster-spray-foam-insulation-specialist/",
    "https://penzu.com/p/a603861146c6ac7c",
    "https://www.skillshare.com/en/profile/Skillshare-Member/260554095",
    "https://www.fixerhub.com/home-service/westminster-spray-foam-insulation-specialist",
    "https://list.ly/list/9Mzr-westminster-spray-foam-insulation-specialist?make_list_mode=true",
    "https://www.topgoogle.com/listing/westminster-spray-foam-insulation-specialist/",
    "https://www.homemavenmember.com/local-businesses/westminster-spray-foam-insulation-specialist",
    "https://speakerdeck.com/sprayfinsulation",
    "https://sprayfinsu1.livejournal.com/profile",
    "https://soundcloud.com/sprayfinsulation",
    "https://www.localbusinesslisting.org/westminster-spray-foam-insulation-specialist",
    "https://webforcompany.com/westminster-spray-foam-insulation-specialist/",
    "https://www.meetyourmarkets.com/westminster-spray-foam-insulation-specialist",
    "https://www.findabusinesspro.com/general-business-1/westminster-spray-foam-insulation-specialist",
    "https://www.techdirectory.io/home-services/westminster-spray-foam-insulation-specialist",
    "https://helpsellmyfsbo.com/westminster-spray-foam-insulation-specialist",
    "https://www.boisemeridian.com/top-level-category/westminster-spray-foam-insulation-specialist",
    "https://www.detroitbusinesscenter.com/services/westminster-spray-foam-insulation-specialist",
    "https://www.buffalosbest.com/home-services/westminster-spray-foam-insulation-specialist",
    "https://www.gunspace.net/westminster-spray-foam-insulation-specialist",
    "https://www.asktwena.directory/home-services/westminster-spray-foam-insulation-specialist",
    "https://www.myadbay.com/home-services/westminster-spray-foam-insulation-specialist",
    "https://www.frontofthebeat.com/professional-development/westminster-spray-foam-insulation-specialist",
    "http://www.pagebook.ws/westminster-spray-foam-insulation-specialist",
    "https://www.constantcard.com/westminster-spray-foam-insulation-specialist",
    "http://www.todo15.com/sample-category/westminster-spray-foam-insulation-specialist",
    "https://www.americansearch.info/home-garden-improvement/westminster-spray-foam-insulation-specialist",
    "https://www.focalenz.com/home-services/westminster-spray-foam-insulation-specialist",
    "https://www.242hub.com/home-services/westminster-spray-foam-insulation-specialist",
    "https://www.elbida.com/professional-services/westminster-spray-foam-insulation-specialist"
]
const DirectoriesOnTheWeb = () => {
    return (
        <>
            <SEO/>
            <PageHeader header={"Find us on the web!"}/>
            <div className="main_wrapper">
                <Box py={20} mt={0}>
                    <List spacing={3} textAlign={'center'}>
                        {urls.map((url, index) => (
                            <ListItem key={index}>
                                <Link href={url} isExternal color="blue.500" target={"_blank"} rel={"noreferrer"}>
                                    {url}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        </>
    );
};

export default DirectoriesOnTheWeb;