import React from "react";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import {SEO} from "../../Components/SEO/SEO";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SiteData} from "../../Constants/siteData";

export const CabinetPainting = () => {
    return (
        <PageWrapper>
            <SEO title={SiteData.cabinetPainting.metaData.title} description={SiteData.cabinetPainting.metaData.description}/>
            <PageHeader header={SiteData.cabinetPainting.h1} subtitle={SiteData.cabinetPainting.subtitle}/>
            <ServicePageTemplate body={SiteData.cabinetPainting.body}/>
        </PageWrapper>
    )
}