import React from "react";
import simivalleyhousepaintingmeta from '../assets/images/johnsoncitymeta.jpg'
import {Box, Button, Container, Heading, Link, List, ListItem, Stack, Text, UnorderedList,} from "@chakra-ui/react";
import {RiCustomerService2Line,} from "react-icons/ri";
import {GiGreenhouse} from "react-icons/gi";
import {
    MdBrush,
    MdBusiness,
    MdCleaningServices,
    MdFormatPaint,
    MdHomeRepairService,
    MdKitchen,
    MdOutlineDeck,
    MdPalette,
    MdWorkOutline,
} from "react-icons/md";
import {BiCertification} from "react-icons/bi";
import {posts} from "./posts";

const phoneNumber = "(720) 619-8255";
const telLink = "tel:7206198255";
const phoneNumberWithDashes = "720-619-8255";

export const SiteData = {
    "hoursOfOperation": "Mon - Fri 8AM - 5PM",
    "city": "Simi Valley, CA",
    "phoneNumber": "805-749-5002",
    "telLink": "tel:+18057495002",
    "buttonCtaText": "Call Now for Expert Painting Services!",
    "emailFooterText": "For all your painting needs, call Simi Valley House Painting at 805-749-5002",
    "keywords": "House painting, interior painting, exterior painting, professional painters, Simi Valley, CA.",
    "footerText": "Simi Valley House Painting, All rights reserved.",
    "ogImage": simivalleyhousepaintingmeta,
    "navLinks": [
        {
            "title": "Home",
            "href": "/"
        },
        {
            "title": "Services",
            "href": "/services/"
        },
        {
            "title": "Blog",
            "href": "/blog/"
        },
        {
            "title": "About",
            "href": "/about/"
        },
        {
            "title": "Contact Us",
            "href": "/contact/"
        },
        {
            "title": "Careers",
            "href": "/careers/",
            "hideFromTopNav": true
        }
    ],
    "signupForm": {
        "intro": "When you're looking to refresh your home or business, Simi Valley House Painting is the premier choice in Simi Valley. Our dedicated team leverages extensive local knowledge and professional expertise to offer top-notch painting services. From detailed interior projects to comprehensive exterior makeovers, we employ environmentally friendly painting techniques, ensuring your property not only looks its best but is also protected.",
        "servicesTitle": "Our Services:"
    },
    "homepage": {
        "url": "https://www.simivalleyhousepainting.com",
        "metaData": {
            "title": "Professional Painting Services in Simi Valley | Call 805-749-5002",
            "description": "Simi Valley House Painting is your trusted partner for high-quality painting services in Simi Valley, CA. Contact us for reliable, eco-friendly painting solutions."
        },
        "footerHeading": "Reach Out to Simi Valley House Painting",
        "footerText": "Connect with us for professional painting services in the Simi Valley area. We're here to transform your property with quality workmanship and vibrant colors.",
        "h1": "Professional House Painting Services in Simi Valley, California",
        "subtitle": "Your Premier Painting Service in Simi Valley, California",
        "footerText2": "Delivering Exceptional Painting Services in Simi Valley, California",
        "values": [
            {
                "id": 1,
                "number": "25+ Years",
                "name": "Experience in Painting",
                "icon": MdWorkOutline
            },
            {
                "id": 2,
                "number": "99%",
                "name": "Customer Satisfaction Rate",
                "icon": RiCustomerService2Line
            },
            {
                "id": 3,
                "number": "Eco-Friendly",
                "name": "Painting Practices",
                "icon": GiGreenhouse
            },
            {
                "id": 4,
                "number": "Certified",
                "name": "Professional Painters",
                "icon": BiCertification
            }
        ],
        "services": <>
            <Text>
                At Simi Valley House Painting, we're dedicated to transforming your space with a full spectrum of
                painting
                services. Our team combines expertise, precision, and the finest materials to bring your vision to life.
                Explore
                our services:
            </Text>
            <UnorderedList styleType="none" spacing={3} mt={5}>
                <ListItem>
                    <Text>
                        <Link href="/interior-painting-simi-valley" fontWeight="bold">Interior
                            Painting:</Link> Transform your
                        indoor spaces with our expert interior painting services. We offer personalized color
                        consultations and use
                        premium, low-VOC paints for a stunning, healthy home environment.
                    </Text>
                </ListItem>
                <ListItem>
                    <Text>
                        <Link href="/exterior-painting-simi-valley" fontWeight="bold">Exterior Painting:</Link> Boost
                        your curb
                        appeal with our professional <a
                        href={'https://www.housepaintingnewbritain.com/exterior-painting.html'}>exterior
                        painting</a> services. Our team ensures durable,
                        weather-resistant
                        finishes that protect and beautify your home or business.
                    </Text>
                </ListItem>
                <ListItem>
                    <Text>
                        <Link href="/cabinet-painting-simi-valley" fontWeight="bold">Cabinet Painting:</Link> Revitalize
                        your
                        kitchen or bathroom cabinets with our specialized painting services. Choose from a variety of
                        finishes for a
                        modern update to your cabinetry.
                    </Text>
                </ListItem>
                <ListItem>
                    <Text>
                        <Link href="/color-consulting-simi-valley" fontWeight="bold">Color Consulting:</Link> Not sure
                        which colors
                        to choose? Our expert color consultants can help you select the perfect palette to match your
                        style and
                        enhance your property's appeal.
                    </Text>
                </ListItem>
                <ListItem>
                    <Text>
                        <Link href="/commercial-painting-simi-valley" fontWeight="bold">Commercial Painting:</Link> From
                        office
                        buildings to retail spaces, our commercial painting services provide a fresh, professional look
                        to any
                        business setting, with minimal disruption to your operations.
                    </Text>
                </ListItem>
            </UnorderedList>
            <Text pt={4}>
                Each service is tailored to meet the unique needs of your project, ensuring exceptional results that
                reflect our
                commitment to quality and customer satisfaction. Discover the difference with Simi Valley House Painting
                – where
                your vision meets our expertise.
            </Text>
        </>,
        "servicesHeader": "Our Comprehensive Array of Painting Services for All Your Needs",
        "servicesSubtitle": "Explore our wide range of services – from detailed interior painting to expansive exterior projects, Simi Valley House Painting is your solution for all painting needs in Simi Valley",
        "content": [
            <>
                <Heading as="h2" size={'md'} pb='2'>Simi Valley House Painting: Your Premier Choice for Home
                    Beautification</Heading>
                <Text>
                    Welcome to Simi Valley House Painting, the leading provider of professional painting services in
                    Simi Valley, California. Our dedicated team offers a wide array of <a
                    href={"https://www.parsippanypaintingservices.com/"} target={"_blank"} rel={"noreferrer"}>house
                    painting</a> solutions tailored to
                    meet the unique aesthetic and protective needs of your property. With a commitment to excellence,
                    quality craftsmanship, and customer satisfaction, we are your trusted partner for transforming and
                    enhancing your living or working space.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Comprehensive Painting Services in Simi Valley</Heading>
                <Text>
                    At Simi Valley House Painting, we specialize in a broad spectrum of painting services designed to
                    cater to both residential and commercial properties. Our expertise encompasses interior painting,
                    exterior painting, cabinet refinishing, and more, utilizing the latest techniques and high-quality
                    materials to deliver outstanding results.
                </Text>

                <Stack direction={{base: 'column', lg: 'row'}} spacing={{base: '12', lg: '16'}} flex="1">
                    <Box as="main" role="main" width="full" bg="bg.accent.default">
                        <Stack spacing={3}>
                            <Heading as="h2" size={'md'} pb='2'>Customized Painting Solutions for Every Need</Heading>
                            <Text>
                                Our services at Simi Valley House Painting are designed to address all aspects of
                                painting and surface treatment:
                            </Text>
                            <UnorderedList>
                                <ListItem><Link href="/interior-painting-simi-valley">Interior Painting:</Link> Enhance
                                    the beauty and ambiance of your indoor spaces with our customized interior painting
                                    services.</ListItem>
                                <ListItem><Link href="/exterior-painting-simi-valley">Exterior Painting:</Link> Protect
                                    and beautify your property's exterior with our durable and weather-resistant
                                    painting solutions.</ListItem>
                                <ListItem><Link href="/cabinet-painting-simi-valley">Cabinet Painting:</Link> Revitalize
                                    your cabinets with our professional painting and refinishing services, perfect for
                                    kitchens and bathrooms.</ListItem>
                                <ListItem><Link href="/color-consulting-simi-valley">Color Consulting:</Link> Work with
                                    our color experts to choose the ideal palette that complements your space and
                                    personal style.</ListItem>
                                <ListItem><Link href="/commercial-painting-simi-valley">Commercial
                                    Painting:</Link> Elevate your business's presence with our comprehensive commercial
                                    painting services, from offices to retail spaces.</ListItem>
                            </UnorderedList>
                        </Stack>
                    </Box>
                </Stack>

                <Heading as="h2" size={'md'} pb='2'>Innovative Painting Techniques and High-Quality Materials</Heading>
                <Text>
                    We pride ourselves on leveraging cutting-edge painting techniques and eco-friendly, high-quality
                    materials. This approach not only ensures visually stunning results but also promotes durability,
                    longevity, and environmental responsibility. Our team stays abreast of the latest industry
                    advancements to provide you with superior painting solutions.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Our Dedication to Customer Satisfaction and Quality</Heading>
                <Text>
                    At Simi Valley House Painting, customer satisfaction is our top priority. We go above and beyond to
                    ensure your painting project is completed to your utmost satisfaction, with minimal disruption to
                    your daily routine. Our meticulous attention to detail, professionalism, and commitment to quality
                    have made us the preferred painting service provider in Simi Valley.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Why Choose Simi Valley House Painting?</Heading>
                <Text>
                    Opting for Simi Valley House Painting means selecting a team that values:
                </Text>
                <UnorderedList>
                    <ListItem>Exceptional craftsmanship and comprehensive painting services.</ListItem>
                    <ListItem>Sustainable and environmentally friendly painting practices.</ListItem>
                    <ListItem>Prompt, reliable, and professional service delivery.</ListItem>
                    <ListItem>Customized solutions that meet your specific painting needs.</ListItem>
                    <ListItem>Competitive pricing without compromising on quality.</ListItem>
                </UnorderedList>
                <Text>
                    We are proud to be Simi Valley's leading painting service provider, dedicated to enhancing the
                    beauty and value of your property.
                </Text>

                <Text>
                    Ready to transform your space with expert painting services? For premier painting solutions in Simi
                    Valley, CA, <Link href="tel:805-749-5002">call us at 805-749-5002</Link>. Receive a personalized
                    consultation and discover why we are the top choice for painting services in the area. Our team
                    looks forward to exceeding your expectations and bringing your vision to life.
                </Text>

                <Button as={'a'} href={'tel:805-749-5002'} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for
                    Assistance</Button>
            </>
        ],
        "additionalContent":
            <>
                <Container maxW="container.xl">
                    <Box as="section" pt={10}>
                        <Text mb={4}>
                            Nestled in the vibrant community of Simi Valley, California, Simi Valley House Painting
                            stands as a beacon of professional painting excellence. We're not just a painting service;
                            we are artisans and visionaries committed to transforming homes and businesses with strokes
                            of brilliance and hues of inspiration. Our dedication to enhancing the aesthetic appeal and
                            protection of your property sets us apart, making us your preferred <a
                            href="https://shermanpainters.com/"
                            target="_blank" rel="noreferrer">house
                            painting</a> partner in Simi
                            Valley.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Our Pledge of Painting Excellence
                        </Heading>
                        <Text mb={4}>
                            At Simi Valley House Painting, excellence is not just a goal; it's our standard. We are not
                            just our
                            average <a href={'https://www.housepainterblaine.com/'} target={"_blank"}
                                       rel={'noreferrer'}>house
                            painter</a>. Our team of
                            skilled painters, color consultants, and project managers bring a wealth of experience and a
                            passion for painting to every project. We leverage state-of-the-art techniques, eco-friendly
                            paints, and a meticulous attention to detail to ensure unmatched quality and lasting beauty
                            in every brushstroke.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Diverse Painting Services Tailored to Simi Valley
                        </Heading>
                        <Text mb={4}>
                            Understanding the diverse needs of Simi Valley's homes and businesses, we offer a
                            comprehensive suite of painting services:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Interior Painting:</strong> Transform your indoor spaces with our expert
                                interior services from a skilled <a href={'https://www.meridianhousepainting.com'}
                                                                    target={"_blank"}
                                                                    rel={'noreferrer'}>painter</a>, designed to refresh
                                and rejuvenate
                                every room.
                            </ListItem>
                            <ListItem>
                                <strong>Exterior Painting:</strong> Protect and beautify your property's exterior with
                                our durable and weather-resistant painting solutions.
                            </ListItem>
                            <ListItem>
                                <strong>Cabinet Refinishing:</strong> Give your cabinets a new lease on life with our
                                specialized refinishing services, perfect for kitchens and bathrooms.
                            </ListItem>
                            <ListItem>
                                <strong>Color Consulting: </strong> Collaborate with a professional <a
                                href={'https://www.lakeforesthousepainters.com/'} target={"_blank"} rel={'noreferrer'}>house
                                painter</a> and color expert to find the
                                perfect palette that reflects your style and enhances your space.
                            </ListItem>
                            <ListItem>
                                <strong>Commercial Painting:</strong> Elevate your business's presence with our
                                professional commercial painting, tailored to your brand and operational needs.
                            </ListItem>
                            <ListItem>
                                <strong>Pressure Washing:</strong> Prepare your surfaces for painting or revitalize your
                                property's appearance with our comprehensive pressure washing services.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            A Process Centered on Satisfaction and Quality
                        </Heading>
                        <Text mb={4}>
                            Our approach to painting projects in Simi Valley is built around delivering satisfaction and
                            quality, from initial consultation to the final walkthrough:
                        </Text>
                        <UnorderedList mb={4}>
                            <ListItem>
                                <strong>Personalized Consultation:</strong> We start with a detailed discussion of your
                                vision, preferences, and the specifics of your space.
                            </ListItem>
                            <ListItem>
                                <strong>Customized Project Planning:</strong> Each painting project is meticulously
                                planned to meet your unique needs and schedule.
                            </ListItem>
                            <ListItem>
                                <strong>Expert Execution:</strong> Our team of professional painters ensures that every
                                project is completed with precision, using the highest quality materials.
                            </ListItem>
                            <ListItem>
                                <strong>Comprehensive Clean-up:</strong> We respect your space, ensuring a thorough
                                clean-up after completion to leave your property pristine.
                            </ListItem>
                            <ListItem>
                                <strong>Satisfaction Guarantee:</strong> Your satisfaction is our highest priority, and
                                we stand behind the quality of our work with a comprehensive guarantee.
                            </ListItem>
                        </UnorderedList>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Choose Simi Valley House Painting for Unmatched Quality
                        </Heading>
                        <Text mb={4}>
                            Selecting our company opting for a partner who brings unparalleled
                            expertise in <a href={"https://www.wilsonvillepainters.com/"} target={"_blank"}
                                            rel={"noreferrer"}>house painting</a>, commitment to sustainability, and a
                            customer-first approach to every project.
                            We're proud to serve the Simi Valley community, providing <a
                            href="https://ottawapaintpros.com/"
                            target="_blank" rel="noreferrer">house
                            painting</a> services that exceed
                            expectations and elevate the beauty of your property.
                        </Text>
                    </Box>

                    <Box as="section" pt={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Ready to Transform Your Space?
                        </Heading>
                        <Text mb={4}>
                            If you're in Simi Valley and looking to embark on a painting project, Simi Valley House
                            Painting is here to turn your vision into reality. <Link href="tel:805-749-5002">Contact
                            us</Link> today to schedule your consultation. Discover the difference that a professional
                            <a href={'https://www.housepainterswhittier.com/'} target={"_blank"} rel={'noreferrer'}>house
                                painter</a> can make and why we're the trusted choice for property owners across Simi
                            Valley.
                        </Text>
                        <Button colorScheme="green" size="md" mb={4} as={'a'} href="tel:805-749-5002">
                            Contact Us Now
                        </Button>
                    </Box>
                </Container>
            </>,
        "featuredServices": [
            {
                "name": "Interior Painting",
                "description": "Expert interior painting services to refresh and beautify your living spaces with high-quality, eco-friendly paints.",
                "icon": MdBrush,
                "path": "/interior-painting-simi-valley/"
            },
            {
                "name": "Exterior Painting",
                "description": "Professional exterior painting services designed to enhance curb appeal and protect your property from the elements.",
                "icon": MdFormatPaint,
                "path": "/exterior-painting-simi-valley/"
            },
            {
                "name": "Cabinet Painting",
                "description": "Transform your cabinets with our specialized painting services, offering a fresh and modern look to your kitchen or bathroom.",
                "icon": MdKitchen,
                "path": "/cabinet-painting-simi-valley/"
            },
            {
                "name": "Color Consulting",
                "description": "Personalized color consulting services to help you choose the perfect paint colors that reflect your style and home's aesthetics.",
                "icon": MdPalette,
                "path": "/color-consulting-simi-valley/"
            },
            {
                "name": "Commercial Painting",
                "description": "Comprehensive commercial painting services for businesses of all sizes, ensuring a professional and inviting appearance.",
                "icon": MdBusiness,
                "path": "/commercial-painting-simi-valley/"
            },
            {
                "name": "Deck and Fence Painting",
                "description": "Protect and beautify your outdoor living spaces with our deck and fence painting services, using durable and weather-resistant paints.",
                "icon": MdOutlineDeck,
                "path": "/deck-and-fence-painting-simi-valley/"
            },
            {
                "name": "Stucco Repair and Painting",
                "description": "Expert stucco repair and painting services to fix imperfections and refresh the exterior of your stucco home.",
                "icon": MdHomeRepairService,
                "path": "/stucco-repair-painting-simi-valley/"
            },
            {
                "name": "Pressure Washing",
                "description": "Prepare your surfaces for painting with our professional pressure washing services, ensuring a clean and optimal base for paint application.",
                "icon": MdCleaningServices,
                "path": "/pressure-washing-simi-valley/"
            }
        ],
    },
    "testimonials": {
        "h1": "Testimonials",
        "heading": "Discover why our clients choose us for their painting projects – Simi Valley House Painting is the top-rated choice in Simi Valley",
        "testimonials": [
            {
                "avatarUrl": "https://i.pravatar.cc/150?u=a042581f4e29026704d",
                "name": "Alex J.",
                "logo": undefined,
                "title": "Simi Valley, CA",
                "quote": "\"We recently had our home's exterior painted by Simi Valley House Painting, and the result was fantastic. Their attention to detail and professionalism was outstanding. Our house looks brand new again, and we've received so many compliments from our neighbors.\""
            },
            {
                "avatarUrl": "https://i.pravatar.cc/150?u=a0425d",
                "name": "Mia R.",
                "logo": undefined,
                "title": "Simi Valley, CA",
                "quote": "\"The team at Simi Valley House Painting transformed our interiors with their impeccable painting services. They helped us choose the perfect colors, and their work was clean and efficient. Highly recommend for anyone looking for quality painters in Simi Valley.\""
            },
            {
                "avatarUrl": "https://i.pravatar.cc/150?u=b0266d",
                "name": "Chris P.",
                "logo": undefined,
                "title": "Simi Valley, CA",
                "quote": "\"After hiring Simi Valley House Painting for our commercial property, we were impressed by their professionalism and the quality of their work. They completed the project on time, and the finish was flawless. They're the go-to painting company in Simi Valley.\""
            }
        ]
    },
    services: {
        "metaData": {
            "title": "Painting Services - Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Explore our wide range of top-tier painting services in Simi Valley. From detailed interior painting to durable exterior coatings, our team is ready to transform your property."
        },
        "h1": "Premier Painting Solutions for Your Home and Business in Simi Valley, CA",
        "h2": "Painting Services We Offer",
        "subtext": "At Simi Valley House Painting, our goal is to deliver superior painting services that enhance the beauty and value of your property, all at competitive prices.",
        "footerHeading": "Ready to Transform Your Property?",
        "footerText2": "Get in touch with us at 805-749-5002 or use our online contact form for a free estimate. Experience the difference with Simi Valley House Painting today!",
        "footerText": "Remember, at Simi Valley House Painting, your satisfaction is our top priority. For the best painting services in Simi Valley, we are just a call away!",
        content: (
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">
                        Simi Valley House Painting: Your Premier Painting Professionals in Simi Valley, CA
                    </Heading>
                    <Text>
                        At Simi Valley House Painting, we pride ourselves on delivering exceptional painting services
                        across Simi Valley, CA. From vibrant interior makeovers to durable exterior facelifts, our team
                        of skilled painters is dedicated to transforming your spaces. Leveraging advanced painting
                        techniques and high-quality materials, we ensure your property not only stands out but also
                        withstands the test of time.
                    </Text>

                    <Heading as="h3" size="lg">
                        Why Choose Simi Valley House Painting?
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Experienced Craftsmen:</b> Our team consists of seasoned professionals, each with a
                                keen eye for detail and a passion for painting, guaranteeing top-tier service for every
                                project.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Quality Materials:</b> We use only the best paints and materials, ensuring
                                long-lasting finishes that are as durable as they are beautiful.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Customized Solutions:</b> We understand that every property is unique. That's why we
                                offer tailored painting services designed to meet your specific needs and vision.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Competitive Pricing:</b> Delivering value is at the core of what we do. We offer
                                premium painting services at competitive prices, providing you with exceptional value.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Eco-Friendly Options:</b> Committed to sustainability, we offer eco-friendly paint
                                options that minimize environmental impact without compromising on quality.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Comprehensive Painting Services
                    </Heading>
                    <List spacing={3}>
                        <UnorderedList>
                            <ListItem>
                                <Link href={"/interior-painting-simi-valley"}>
                                    Interior Painting
                                </Link>
                                : Bring new life to your interiors with our comprehensive painting services, customized
                                to your style preferences.
                            </ListItem>
                            <ListItem>
                                <Link href={"/exterior-painting-simi-valley"}>
                                    Exterior Painting
                                </Link>
                                : Enhance your curb appeal with our exterior painting services, designed to protect and
                                beautify your home or business.
                            </ListItem>
                            <ListItem>
                                <Link href={"/cabinet-painting-simi-valley"}>Cabinet Painting</Link>:
                                Transform your cabinets with our specialized painting services, offering a fresh look
                                with durable finishes.
                            </ListItem>
                            <ListItem>
                                <Link href={"/color-consulting-simi-valley"}>
                                    Color Consulting
                                </Link>
                                : Not sure about colors? Our color consulting services can help you choose the perfect
                                palette for your space.
                            </ListItem>
                            <ListItem>
                                <Link href={"/commercial-painting-simi-valley"}>Commercial Painting</Link>: Professional
                                painting
                                services for businesses of all sizes, enhancing your brand's presence and appeal.
                            </ListItem>
                        </UnorderedList>
                    </List>

                    <Heading as="h3" size="lg">
                        Tailored Painting Solutions for Every Need
                    </Heading>
                    <Text>
                        We recognize the diversity of painting projects and offer customized solutions to address your
                        specific requirements, ensuring a flawless finish every time.
                    </Text>

                    <Heading as="h3" size="lg">
                        Unwavering Commitment to Quality and Satisfaction
                    </Heading>
                    <Text>
                        Our dedication to excellence is evident in every stroke of our brush. At
                        Simi Valley House Painting, your satisfaction is our ultimate goal, and we strive to deliver
                        painting services that exceed your expectations.
                    </Text>

                    <Heading as="h3" size="lg">
                        Get in Touch for Premier Painting Services
                    </Heading>
                    <Text>
                        Ready to transform your space with vibrant colors and professional finishes? Contact
                        Simi Valley House Painting today for unparalleled painting services in Simi Valley, CA.
                    </Text>
                </Stack>
            </Box>
        ),
        services: [
            {
                "id": "0",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/interior-painting-simi-valley"}>Interior Painting</Link>:
                        </b> Transform your home's atmosphere with our expert interior painting services. Our skilled
                        painters work with you to bring your vision to life, ensuring vibrant and lasting results.
                    </Text>
                )
            },
            {
                "id": "1",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/exterior-painting-simi-valley"}>Exterior Painting</Link>:
                        </b> Elevate your property's curb appeal with our professional exterior painting services. From
                        preparation to the final coat, we guarantee a flawless finish that stands the test of time.
                    </Text>
                )
            },
            {
                "id": "2",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/cabinet-painting-simi-valley"}>Cabinet Painting</Link>:
                        </b> Give your cabinets a new lease on life with our cabinet painting services. Choose from a
                        wide range of colors and finishes to match your style and enhance your space.
                    </Text>
                )
            },
            {
                "id": "3",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/color-consulting-simi-valley"}>Color Consulting</Link>:
                        </b> Unsure of which colors to choose? Our color consulting service provides expert advice to
                        help you select the perfect palette that reflects your taste and complements your home.
                    </Text>
                )
            },
            {
                "id": "4",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-painting-simi-valley"}>Commercial Painting</Link>:
                        </b> Enhance your business's appearance with our commercial painting services. We deliver
                        high-quality, efficient painting solutions that align with your business's brand and aesthetic.
                    </Text>
                )
            },
            {
                "id": "5",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/deck-and-fence-painting-simi-valley"}>Deck and Fence Painting</Link>:
                        </b> Protect and beautify your outdoor living spaces with our deck and fence painting services.
                        Our durable finishes ensure your deck and fence look great and last longer.
                    </Text>
                )
            },
            {
                "id": "6",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/stucco-repair-painting-simi-valley"}>Stucco Repair and Painting</Link>:
                        </b> Specializing in stucco repair and painting, we address imperfections and apply high-quality
                        paint that revitalizes your home's exterior with a smooth, durable finish.
                    </Text>
                )
            },
            {
                "id": "7",
                "text": () => (
                    <Text>
                        <b>
                            <Link href={"/pressure-washing-simi-valley"}>Pressure Washing</Link>:
                        </b> Prepare your surfaces for painting with our professional pressure washing services. We
                        remove dirt, mold, and mildew, ensuring the best possible adherence and finish of the paint.
                    </Text>
                )
            }
        ],
        "whyh2": "Why Choose Simi Valley House Painting?",
        "whySubtext": () => (
            <>
                Our team at Simi Valley House Painting has been delivering exceptional painting services in Simi
                Valley, CA, for years. We focus on meeting your specific needs to ensure the best outcomes for your
                property. Reach out to us at{" "}
                <Link href={"tel:+18057495002"} textDecoration={"underline"}>
                    805-749-5002
                </Link>
                .
            </>
        ),
        "whyServices": [
            {
                "id": "0",
                "text": () => (
                    <Text>
                        <b>Expert Team:</b> Our dedicated professionals are trained in the latest painting techniques,
                        ensuring top-quality service for any project, big or small.
                    </Text>
                ),
            },
            {
                "id": "1",
                "text": () => (
                    <Text>
                        <b>Customer-Centric Service:</b> We prioritize your needs and satisfaction, maintaining open
                        communication and transparency throughout the project.
                    </Text>
                ),
            },
            {
                "id": "2",
                "text": () => (
                    <Text>
                        <b>Efficiency and Reliability:</b> Known for our efficiency and reliability, we have established
                        a strong reputation in the Simi Valley community.
                    </Text>
                ),
            },
            {
                "id": "3",
                "text": () => (
                    <Text>
                        <b>Local Expertise:</b> Our deep understanding of Simi Valley's unique climate and architectural
                        styles ensures tailored and effective painting solutions for your property.
                    </Text>
                ),
            },
        ],
    },
    "interiorPainting": {
        "url": "https://www.simivalleyhousepainting.com/interior-painting-simi-valley",
        "metaData": {
            "title": "Professional Interior Painting in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Discover top-notch interior painting services in Simi Valley, CA with Simi Valley House Painting. Our expert painters transform your space with precision and care."
        },
        "h1": "Expert Interior Painting Services in Simi Valley, CA",
        "subtitle": "Elevate Your Home with Our Professional Interior Painting Services in Simi Valley",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Transform Your Home's Interior</Heading>
                    <Text>At Simi Valley House Painting, we specialize in transforming the interiors of your Simi
                        Valley home with high-quality painting services. From selecting the perfect color palette to our
                        meticulous application process, we ensure a flawless finish that enhances the beauty and value
                        of your home.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Custom Color Consultation:</b> Our experts work with you to choose colors that
                            reflect your personal style and complement your home's design.</ListItem>
                        <ListItem><b>Premium Quality Paints:</b> We use only the best paints, ensuring durability,
                            vibrant colors, and an environmentally friendly choice.</ListItem>
                        <ListItem><b>Detailed Preparation:</b> Our thorough preparation process includes repairing minor
                            wall damages, sanding, and priming to achieve the best results.</ListItem>
                        <ListItem><b>Efficient and Clean Work:</b> Our team respects your space, ensuring a quick,
                            efficient process with minimal disruption and clean up after completion.</ListItem>
                        <ListItem><b>Satisfaction Guaranteed:</b> We pride ourselves on customer satisfaction, offering
                            personalized service and attention to detail from start to finish.</ListItem>
                    </UnorderedList>
                    <Text>Whether refreshing a single room or undertaking a complete home makeover, our interior
                        painting services in Simi Valley are tailored to meet your needs. Let us help you create the
                        home of your dreams with our professional painting services.</Text>
                </Stack>
            </Box>
        )
    },
    "exteriorPainting": {
        "url": "https://www.simivalleyhousepainting.com/exterior-painting-simi-valley",
        "metaData": {
            "title": "Superior Exterior Painting Services in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Elevate your home's curb appeal with our exterior painting services in Simi Valley, CA. Trust Simi Valley House Painting for durable, beautiful finishes."
        },
        "h1": "Premium Exterior Painting in Simi Valley, CA",
        "subtitle": "Boost Your Home's Curb Appeal with Our Expert Exterior Painting",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Revitalize Your Home's Exterior</Heading>
                    <Text>Revitalize the look and feel of your Simi Valley home with our expert exterior painting
                        services. At Simi Valley House Painting, we understand the impact of a fresh coat of paint on
                        your home's overall appeal and value. Our team is committed to delivering exceptional quality
                        and durability, using the best materials and techniques to protect your home against the
                        elements.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Thorough Surface Preparation:</b> We begin with comprehensive surface preparation
                            to ensure the paint adheres well and lasts longer.</ListItem>
                        <ListItem><b>High-Quality Paints:</b> Our use of premium paints ensures a vibrant, lasting color
                            that withstands weather and wear.</ListItem>
                        <ListItem><b>Attention to Detail:</b> From precise trim painting to seamless finishes, our
                            attention to detail ensures a flawless look.</ListItem>
                        <ListItem><b>Protection for Your Property:</b> We take care to protect your landscaping,
                            furniture, and other outdoor elements during the painting process.</ListItem>
                        <ListItem><b>Eco-Friendly Solutions:</b> Our commitment to the environment means using
                            eco-friendly paints and practices for every project.</ListItem>
                    </UnorderedList>
                    <Text>Let us transform your exterior into something you'll be proud of. With
                        Simi Valley House Painting, you're choosing a partner dedicated to bringing your vision to life
                        with professionalism and skill.</Text>
                </Stack>
            </Box>
        )
    },
    "cabinetPainting": {
        "url": "https://www.simivalleyhousepainting.com/cabinet-painting-simi-valley",
        "metaData": {
            "title": "Professional Cabinet Painting in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Transform your kitchen or bathroom with our cabinet painting services in Simi Valley, CA. Experience a cost-effective makeover with Simi Valley House Painting."
        },
        "h1": "Transformative Cabinet Painting Services in Simi Valley, CA",
        "subtitle": "Give Your Cabinets a Fresh, New Look with Our Expert Painting Services",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Expert Cabinet Refinishing</Heading>
                    <Text>Revitalize the heart of your home with our cabinet painting services in Simi Valley.
                        Simi Valley House Painting specializes in breathing new life into your kitchen or bathroom
                        cabinets, offering a cost-effective way to update your space without the need for a full
                        remodel. Our skilled painters utilize the latest techniques and highest quality materials to
                        deliver stunning, durable finishes.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Custom Color Selection:</b> Collaborate with our color experts to find the perfect
                            shade that complements your interior design and personal taste.</ListItem>
                        <ListItem><b>Superior Finish:</b> We apply advanced painting techniques to achieve a smooth,
                            flawless finish that resists peeling, chipping, and staining.</ListItem>
                        <ListItem><b>Quick Turnaround:</b> Our efficient process minimizes disruption to your daily
                            life, delivering a transformative look in a timely manner.</ListItem>
                        <ListItem><b>Durable Results:</b> We use premium paints and sealants to ensure your cabinets
                            withstand the test of time and daily use.</ListItem>
                        <ListItem><b>Eco-Friendly Options:</b> Choose from a variety of eco-friendly paint options that
                            are safer for your family and the planet.</ListItem>
                    </UnorderedList>
                    <Text>With Simi Valley House Painting, upgrading your cabinets is hassle-free and rewarding. Let us
                        help you create the kitchen or bathroom of your dreams with our professional cabinet painting
                        services in Simi Valley.</Text>
                </Stack>
            </Box>
        )
    },
    "colorConsulting": {
        "url": "https://www.simivalleyhousepainting.com/color-consulting-simi-valley",
        "metaData": {
            "title": "Expert Color Consulting Services in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Choose the perfect colors for your home with our professional color consulting services in Simi Valley, CA. Let Simi Valley House Painting help bring your vision to life."
        },
        "h1": "Professional Color Consulting in Simi Valley, CA",
        "subtitle": "Find Your Ideal Palette with Our Expert Color Consulting",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Discover Your Perfect Colors</Heading>
                    <Text>Choosing the right colors for your home can be overwhelming. Our color consulting services in
                        Simi Valley are designed to help you navigate the vast world of color. At
                        Simi Valley House Painting, we understand the impact of color on mood, space, and style. Our
                        expert color consultants work closely with you to select a palette that reflects your personal
                        taste and complements your home’s architecture and natural lighting.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Personalized Consultation:</b> We take the time to understand your vision,
                            preferences, and lifestyle to recommend colors that truly resonate with you.</ListItem>
                        <ListItem><b>Expert Guidance:</b> Benefit from our extensive knowledge of color theory, trends,
                            and application techniques to make informed decisions.</ListItem>
                        <ListItem><b>Visualization Tools:</b> Utilize advanced tools and samples to visualize how
                            different colors will look in your space before making a final decision.</ListItem>
                        <ListItem><b>Comprehensive Solutions:</b> Whether you’re painting a single room or your entire
                            home, we provide cohesive color schemes that enhance flow and harmony.</ListItem>
                        <ListItem><b>Satisfaction Guaranteed:</b> Our goal is to ensure you are absolutely delighted
                            with your color choices and the final result.</ListItem>
                    </UnorderedList>
                    <Text>With Simi Valley House Painting’s color consulting services, you can confidently choose
                        colors that will transform your home into a space that feels uniquely yours. Let us guide you to
                        your perfect palette in Simi Valley.</Text>
                </Stack>
            </Box>
        )
    },
    "commercialPainting": {
        "url": "https://www.simivalleyhousepainting.com/commercial-painting-simi-valley",
        "metaData": {
            "title": "Professional Commercial Painting Services in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Elevate your business's appearance with top-quality commercial painting services in Simi Valley, CA. Rely on Simi Valley House Painting for efficient and standout results."
        },
        "h1": "Elevate Your Business with Our Commercial Painting Services in Simi Valley, CA",
        "subtitle": "Professional Commercial Painting for Every Business",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Transform Your Business Space</Heading>
                    <Text>Make a lasting impression on your clients and employees with our commercial painting services
                        in Simi Valley. At Simi Valley House Painting, we understand the importance of maintaining a
                        professional and inviting business environment. Our team delivers high-quality, durable painting
                        solutions tailored to your business needs, schedule, and budget.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Customized Service:</b> From retail spaces to corporate offices, we provide
                            customized painting solutions that reflect your brand and enhance your space.</ListItem>
                        <ListItem><b>Minimal Disruption:</b> Our efficient painting process is designed to minimize
                            disruption, allowing you to continue business operations smoothly.</ListItem>
                        <ListItem><b>Durable & High-Quality Finishes:</b> We use premium paints and materials that stand
                            up to the demands of a commercial environment, ensuring a long-lasting, pristine
                            finish.</ListItem>
                        <ListItem><b>Expert Team:</b> Our professional painters have the skills and experience to handle
                            projects of any size, delivering exceptional results on time and within budget.</ListItem>
                        <ListItem><b>Eco-Friendly Options:</b> We offer a selection of eco-friendly paint options,
                            contributing to a healthier environment for your employees and customers.</ListItem>
                    </UnorderedList>
                    <Text>Partner with Simi Valley House Painting for your commercial painting needs in Simi Valley and
                        watch as we transform your business space into one that's vibrant, welcoming, and uniquely
                        yours.</Text>
                </Stack>
            </Box>
        )
    },
    "deckAndFencePainting": {
        "url": "https://www.simivalleyhousepainting.com/deck-and-fence-painting-simi-valley",
        "metaData": {
            "title": "Deck and Fence Painting Services in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Protect and beautify your outdoor spaces with our deck and fence painting services in Simi Valley, CA. High-quality, durable finishes by Simi Valley House Painting."
        },
        "h1": "Premium Deck and Fence Painting in Simi Valley, CA",
        "subtitle": "Enhance Your Outdoor Living Spaces with Professional Painting",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Revitalize Your Deck and Fence</Heading>
                    <Text>Transform your outdoor living areas with our specialized deck and fence painting services in
                        Simi Valley. At Simi Valley House Painting, we recognize the importance of maintaining and
                        enhancing the beauty of your outdoor spaces. Our expert team provides professional painting
                        solutions that not only improve the aesthetic appeal of your deck and fence but also protect
                        them from the elements.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Custom Color Matching:</b> We help you select or match the perfect color to
                            complement your home and landscape, creating a cohesive look for your outdoor
                            area.</ListItem>
                        <ListItem><b>Superior Protection:</b> Our painting services include thorough preparation and the
                            application of high-quality paints and stains designed to protect your deck and fence from
                            weathering, UV damage, and wear.</ListItem>
                        <ListItem><b>Detailed Prep Work:</b> We ensure a lasting finish with detailed prep work,
                            including cleaning, sanding, and priming as needed, to prepare the surface for painting or
                            staining.</ListItem>
                        <ListItem><b>Efficient & Clean:</b> Our team works efficiently to minimize disruption to your
                            daily life, ensuring a clean work area during and after the project completion.</ListItem>
                        <ListItem><b>Expert Advice:</b> Benefit from our expertise with guidance on maintenance and care
                            to keep your deck and fence looking great for years to come.</ListItem>
                    </UnorderedList>
                    <Text>Let Simi Valley House Painting enhance the beauty and longevity of your deck and fence.
                        Experience the difference with our professional, reliable painting services in Simi
                        Valley.</Text>
                </Stack>
            </Box>
        )
    },
    "stuccoRepairAndPainting": {
        "url": "https://www.simivalleyhousepainting.com/stucco-repair-and-painting-simi-valley",
        "metaData": {
            "title": "Stucco Repair and Painting Services in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Restore and refresh your stucco exterior with premier stucco repair and painting services in Simi Valley, CA. Experience lasting beauty and protection with Simi Valley House Painting."
        },
        "h1": "Expert Stucco Repair and Painting in Simi Valley, CA",
        "subtitle": "Professional Stucco Solutions for Your Home",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Revitalize Your Stucco Exterior</Heading>
                    <Text>Stucco is a popular choice for homes in Simi Valley, offering both aesthetic appeal and
                        durability. However, over time, stucco can develop cracks and weathering.
                        Simi Valley House Painting specializes in stucco repair and painting, providing solutions that
                        not only repair damage but also enhance your home’s exterior with a fresh, protective coat of
                        paint.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Comprehensive Stucco Repair:</b> Our team expertly addresses cracks, holes, and
                            other damage to restore the integrity and appearance of your stucco exterior.</ListItem>
                        <ListItem><b>High-Quality Painting:</b> We apply premium exterior paints that are specially
                            formulated for stucco, ensuring a vibrant, long-lasting finish that stands up to the Simi
                            Valley climate.</ListItem>
                        <ListItem><b>Color Consultation:</b> Choose the perfect color for your home with the help of our
                            color consulting services, enhancing curb appeal and reflecting your personal
                            style.</ListItem>
                        <ListItem><b>Protection Against Elements:</b> Our stucco painting includes the application of
                            durable, weather-resistant coatings that protect your home from moisture, UV rays, and
                            temperature extremes.</ListItem>
                        <ListItem><b>Professional Service:</b> From initial assessment to final walkthrough, our team
                            delivers professional, courteous service, ensuring your complete satisfaction with the
                            project outcome.</ListItem>
                    </UnorderedList>
                    <Text>With Simi Valley House Painting, revitalize your stucco exterior to enhance its beauty and
                        longevity. Contact us for top-tier stucco repair and painting services in Simi Valley,
                        CA.</Text>
                </Stack>
            </Box>
        )
    },
    "pressureWashing": {
        "url": "https://www.simivalleyhousepainting.com/pressure-washing-simi-valley",
        "metaData": {
            "title": "Professional Pressure Washing Services in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Revitalize your property's appearance with our professional pressure washing services in Simi Valley, CA. Clean, prepare, and protect your surfaces with Simi Valley House Painting."
        },
        "h1": "Top-Quality Pressure Washing in Simi Valley, CA",
        "subtitle": "Enhance Your Home’s Beauty and Longevity with Professional Pressure Washing",
        "body": (
            <Box>
                <Stack spacing={3} py={5}>
                    <Heading as={'h3'}>Restore Your Property’s Appearance</Heading>
                    <Text>Over time, dirt, grime, and environmental pollutants can tarnish the appearance of your home
                        or business. Simi Valley House Painting’s pressure washing services in Simi Valley, CA, offer a
                        powerful solution to restore and rejuvenate your property’s exterior. Our expert team uses
                        state-of-the-art equipment and techniques to effectively remove unsightly buildup from your
                        siding, driveways, decks, and fences, preparing surfaces for painting or simply revitalizing
                        their look.</Text>
                    <UnorderedList spacing={3}>
                        <ListItem><b>Effective Cleaning Solution:</b> Our pressure washing effectively removes dirt,
                            mold, mildew, and other stubborn stains, enhancing your property's curb appeal.</ListItem>
                        <ListItem><b>Surface Preparation:</b> Ideal for preparing your home for a new coat of paint,
                            pressure washing ensures optimal paint adhesion and longevity.</ListItem>
                        <ListItem><b>Protect Your Investment:</b> Regular cleaning extends the life of your exterior
                            surfaces, protecting your investment by preventing decay and damage.</ListItem>
                        <ListItem><b>Eco-Friendly:</b> Pressure washing is an environmentally friendly way to clean your
                            property, using the power of water instead of harsh chemicals.</ListItem>
                        <ListItem><b>Professional and Efficient:</b> Our experienced team provides fast, efficient
                            service, minimizing disruption to your daily life while delivering exceptional
                            results.</ListItem>
                    </UnorderedList>
                    <Text>Let Simi Valley House Painting help you maintain and enhance the beauty and value of your
                        property with our professional pressure washing services. Experience a cleaner, brighter
                        exterior with our expert team in Simi Valley, CA.</Text>
                </Stack>
            </Box>
        )
    },
    "careers": {
        "url": "https://www.simivalleyhousepainting.com/careers/",
        "metaData": {
            "title": "Painting Careers in Simi Valley, CA | simivalleyhousepainting.com",
            "description": "Explore rewarding painting careers with Simi Valley House Painting in Simi Valley. Join our team and be part of a leading painting service provider."
        },
        "h1": "Join Our Painting Team in Simi Valley, CA",
        "h2": [
            "Why Work with Simi Valley House Painting?",
            "Current Painting Job Openings",
            "Commitment to Employee Growth and Excellence"
        ],
        "headingText": {
            "whyJoin": "Be part of an innovative painting team, where we value skill development and career advancement. Join us in delivering high-quality painting services across Simi Valley.",
            "openings": "Explore our job openings and find a role that matches your passion and skills in the painting industry.",
            "commitment": "We prioritize our team's growth through ongoing training, competitive benefits, and a supportive work environment, fostering both personal and professional development."
        },
        "content": [
            <Text>
                Welcome to the careers page at Simi Valley House Painting. We are passionate about transforming spaces
                with our painting services and are looking for individuals who share our dedication to craftsmanship and
                quality. Join our team to make a significant impact in Simi Valley and beyond.
            </Text>,
            <Text>
                We seek talented painters and support staff who are committed to excellence and ready to learn. We offer
                a nurturing environment where your skills will be appreciated and developed.
            </Text>,
            <Text>
                Our culture is built on growth, offering comprehensive training, competitive benefits, and the latest in
                painting technology and techniques. We aim to create a fulfilling workplace for everyone.
            </Text>,
            <Text>
                As a respected painting service provider in Simi Valley, we uphold values of integrity and
                professionalism. We support our team members with positive work conditions and opportunities for
                advancement.
            </Text>,
            <Text>
                Interested in becoming part of a leading painting team? Check out our current job openings. Whether
                you're skilled in painting or in customer service and administration, there's a place for you at
                Simi Valley House Painting.
            </Text>,
            <Text>
                <Link href="/careers/">Find your next career opportunity</Link> with us. Join a team that's at the
                forefront of the painting industry. At Simi Valley House Painting, you're essential to our story of
                success. Apply now and advance your career.
            </Text>
        ]
    },
    about: {
        "url": "https://www.simivalleyhousepainting.com/about/",
        "metaData": {
            "title": "About Simi Valley House Painting - Premier Painting Services in Simi Valley",
            "description": "Learn about Simi Valley House Painting, your trusted painting service provider in Simi Valley, CA. Discover our commitment to quality and customer satisfaction."
        },
        "h1": "About Simi Valley House Painting",
        "h2": [
            "Our Dedication to Quality Painting Services",
            "Meet Our Team of Painting Experts",
            "Our Comprehensive Painting Services"
        ],
        "headingText": {
            "commitment": "Committed to delivering exceptional painting services to the Simi Valley community, enhancing both beauty and value of your properties.",
            "team": "Our team of painting professionals is highly skilled and dedicated, ensuring superior craftsmanship in every project.",
            "services": "From residential to commercial painting projects, explore our wide range of services designed to meet your specific needs."
        },
        "services": [
            {
                "id": "0",
                "title": "Interior Painting",
                "href": "/interior-painting-simi-valley",
                "icon": MdBrush,
                "description": (
                    <Text>
                        Elevate the interior of your Simi Valley home with our Interior Painting services. We specialize
                        in transforming living spaces with vibrant colors and exquisite finishes. Our team collaborates
                        with you to bring your vision to life, ensuring each room reflects your style while enhancing
                        the overall ambiance of your home. Experience the difference with our meticulous attention to
                        detail and commitment to quality.
                    </Text>
                )
            },
            {
                "id": "1",
                "title": "Exterior Painting",
                "href": "/exterior-painting-simi-valley",
                "icon": MdFormatPaint,
                "description": (
                    <Text>
                        Protect and beautify your home's exterior with our Exterior Painting services in Simi Valley.
                        Our experts provide durable, weather-resistant finishes that not only enhance curb appeal but
                        also safeguard your home against the elements. From color selection to the final brushstroke, we
                        ensure a flawless application that stands the test of time.
                    </Text>
                )
            },
            {
                "id": "2",
                "title": "Cabinet Painting",
                "href": "/cabinet-painting-simi-valley",
                "icon": MdKitchen,
                "description": (
                    <Text>
                        Transform your kitchen or bathroom with our Cabinet Painting services. Revitalize your cabinets
                        with a fresh coat of paint, choosing from a wide array of colors and finishes. Our precise
                        technique ensures a smooth, durable finish, breathing new life into your cabinets and refreshing
                        the heart of your home.
                    </Text>
                )
            },
            {
                "id": "3",
                "title": "Color Consulting",
                "href": "/color-consulting-simi-valley",
                "icon": MdPalette,
                "description": (
                    <Text>
                        Unsure of the perfect palette for your painting project? Our Color Consulting services assist
                        you in selecting the ideal colors to match your home's style and your personal taste. Benefit
                        from our expert advice to create harmonious spaces that reflect your unique aesthetic
                        preferences.
                    </Text>
                )
            },
            {
                "id": "4",
                "title": "Commercial Painting",
                "href": "/commercial-painting-simi-valley",
                "icon": MdBusiness,
                "description": (
                    <Text>
                        Enhance your commercial property with our Commercial Painting services. From offices to retail
                        spaces, we deliver high-quality, professional finishes that elevate your business environment.
                        Our efficient process ensures minimal disruption, allowing you to maintain business operations
                        seamlessly.
                    </Text>
                )
            },
            {
                "id": "5",
                "title": "Deck and Fence Painting",
                "href": "/deck-and-fence-painting-simi-valley",
                "icon": MdOutlineDeck,
                "description": (
                    <Text>
                        Extend the life and beauty of your deck and fence with our Painting services. We provide
                        thorough preparation and application of premium stains and paints to protect and beautify your
                        outdoor living spaces, ensuring they withstand the test of time and elements.
                    </Text>
                )
            },
            {
                "id": "6",
                "title": "Stucco Repair and Painting",
                "href": "/stucco-repair-and-painting-simi-valley",
                "icon": MdHomeRepairService,
                "description": (
                    <Text>
                        Specializing in Stucco Repair and Painting, we address and rectify any imperfections in your
                        stucco exterior, followed by a professional paint application. Our services not only enhance the
                        aesthetic appeal but also add a layer of protection against weathering and cracks, ensuring a
                        pristine finish.
                    </Text>
                )
            },
            {
                "id": "7",
                "title": "Pressure Washing",
                "href": "/pressure-washing-simi-valley",
                "icon": MdCleaningServices,
                "description": (
                    <Text>
                        Prepare your property for a fresh coat of paint or simply rejuvenate its appearance with our
                        Pressure Washing services. Our high-powered cleaning techniques effectively remove dirt, mold,
                        and grime, revitalizing the look of your home or business in Simi Valley without the use of
                        harsh chemicals.
                    </Text>
                )
            }
        ],
    },
    "contact": {
        "url": "https://www.simivalleyhousepainting.com/contact",
        "metaData": {
            "title": "Contact Simi Valley House Painting | Call 805-749-5002",
            "description": "Contact Simi Valley House Painting for premium painting services in Simi Valley. Ensure your home or business looks its best with our expert team."
        },
        "h1": "Ready to Transform Your Space?",
        "subtitle": "Thank you for considering us for your painting needs. As a local, community-focused business, every project is a reflection of our commitment to excellence. Save our contact as your go-to painting service provider in Simi Valley.",
        "sectionHeading": "Why Choose Simi Valley House Painting?",
        "signUpDescription": "Prefer to reach us online? Please fill out the form below, and we'll get back to you as quickly as possible.",
        "feedbackText": "We appreciate feedback from our clients and the Simi Valley community. For non-urgent inquiries or feedback, please use our online form, and a painting expert will contact you.",
        "closingText": (
            <Text align="center">
                Call us and discover why we're the preferred painting service in the neighborhood.
            </Text>
        ),
        "valueProps": [
            {
                "title": "Quality and Excellence:",
                "description": "We commit to the highest quality paint and craftsmanship to protect and beautify your home or business."
            },
            {
                "title": "Advanced Techniques & Tools:",
                "description": "Our painters are skilled in the latest techniques and use state-of-the-art tools for flawless results."
            },
            {
                "title": "Transparent Pricing:",
                "description": "With our no surprises policy, we provide clear pricing and thorough walkthroughs of our service process."
            },
            {
                "title": "Eco-Friendly Practices:",
                "description": "We prioritize your health and the environment by using the safest, most sustainable painting options available."
            },
            {
                "title": "Pride in Our Work:",
                "description": "Our team isn't satisfied until we've delivered the best possible service and transformed your space."
            }
        ]
    },
    "blog": {
        "url": "https://www.simivalleyhousepainting.com/blog",
        "metaData": {
            "title": "Simi Valley House Painting: Quality Painting Solutions | Blog",
            "description": "Explore the latest in painting trends, tips, and insights with Simi Valley House Painting. Our blog is your resource for all things painting in Simi Valley, CA."
        },
        "h1": "Simi Valley House Painting Blog",
        "h2": "Latest Painting Insights",
        "heading": "At Simi Valley House Painting, we're dedicated to providing top-notch painting services that enhance the beauty and value of your home or business. Stay informed with our expert blog posts.",
        posts
    },
    "404": {
        "metaData": {
            "title": "Page Not Found - Simi Valley House Painting | Quality Painting Services | (805) 749-5002",
            "description": "Oops! The page you're looking for could not be found. Simi Valley House Painting offers professional painting services. Contact us for unmatched quality."
        },
        "h1": "404 - Oops, looks like an empty paint bucket here.",
        "subtitle": "Sorry, the page you are looking for does not exist.",
        "p": "Whether you're looking for residential or commercial painting services, or just need expert advice, we're here to help. Contact us today at (805) 749-5002."
    }
};
