import React from 'react';
import {SiteData} from "../../Constants/siteData";
import {Button, Link, useBreakpointValue} from "@chakra-ui/react";

const Slider = () => {
    const isMobile = useBreakpointValue({base: true, md: false})
    return (
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="slide_content">
                                <Link href={SiteData.telLink} _hover={{textDecoration: 'none'}}>
                                    <span className="sub_heading">CALL US ANYTIME<span
                                        style={{fontSize: isMobile ? 'initial' : '40px'}}> {SiteData.phoneNumber}</span>
                                    </span>
                                </Link>
                                <h1 className="heading">{SiteData.homepage.h1}</h1>
                                <p className="desc">{SiteData.homepage.subtitle}</p>

                                <Button as={'a'} href={SiteData.telLink} bg={'#ffa903'} fontSize={'23px'}
                                        color={'#051050'} fontWeight={'bold'}
                                        _hover={{bg: '#ffa903', color: 'black', cursor: 'pointer'}}
                                        p={'16px 30px'} border-radius={'0'} mt={'2rem'} borderRadius={0}
                                        lineHeight={'26px'}
                                        minH={'66px'}
                                        textTransform={'uppercase'}>CONTACT US TODAY</Button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;