import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import {SiteData} from "../../Constants/siteData";
import {SEO} from "../../Components/SEO/SEO";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";

const Services01 = () => {
    return (
        <PageWrapper>
            <SEO description={SiteData.services.metaData.description} title={SiteData.services.metaData.title}/>
            <PageHeader header={SiteData.services.h1} subtitle={SiteData.services.h2}/>
            <ServicePageTemplate body={SiteData.services.content}/>
        </PageWrapper>
    );
};

export default Services01;