import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import TopFeatures from '../Components/TopFeatures';
import Banner from '../Containers/Banner';
import ExperienceAbout from '../Containers/Experiences/ExperienceAbout';
import Testimonial01 from '../Containers/Testimonials/Testimonial01';
import {SiteData} from "../Constants/siteData";
import {SEO} from "../Components/SEO/SEO";

const About = () => {
    return (
        <>
            <SEO description={SiteData.about.metaData.description} title={SiteData.about.metaData.title}/>
            <PageHeader header={SiteData.about.h1} subtitle={SiteData.about.h2[0]}/>
            <div className="main_wrapper">
                <ExperienceAbout/>

                <Banner
                    title={SiteData.contact.closingText}
                    heading='Call Us Anytime'
                    phone={SiteData.phoneNumber}
                    bannerType='banner banner_bg_color'
                />
                <Testimonial01 pb='pd_btom_110'/>
                <TopFeatures/>
            </div>
        </>
    );
};

export default About;