export const posts = [
    {
        id: '1',
        title: 'Choosing the Right Paint Finish for Your Simi Valley Home',
        excerpt: 'The finish of your paint can dramatically affect the overall look and feel of your room. This guide will help you understand the different types of paint finishes available and which ones are best suited for each room in your Simi Valley home.',
        slug: 'choosing-the-right-paint-finish',
        tags: ['paint finishes', 'interior design', 'home improvement'],
        metaDescription: 'Explore how to select the perfect paint finish for every room in your Simi Valley home, enhancing aesthetics and durability.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        id: '2',
        title: 'The Importance of Professional Surface Preparation for Painting',
        excerpt: 'A flawless paint job starts with proper surface preparation. Learn why professional surface preparation is critical to achieving a lasting and high-quality finish for your painting projects.',
        slug: 'professional-surface-preparation',
        tags: ['surface preparation', 'painting tips', 'professional painting'],
        metaDescription: 'Discover the crucial role of professional surface preparation in ensuring a durable and high-quality paint finish for your property.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        id: '3',
        title: 'Eco-Friendly Painting Solutions for Your Home',
        excerpt: 'As environmental awareness grows, so does the demand for eco-friendly painting options. Find out how simivalleyhousepainting.com is leading the way in Simi Valley with sustainable painting solutions that don’t compromise on quality.',
        slug: 'eco-friendly-painting-solutions',
        tags: ['eco-friendly', 'sustainable painting', 'home care'],
        metaDescription: 'Learn about the benefits of eco-friendly painting solutions offered by simivalleyhousepainting.com and how they contribute to a healthier home and planet.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        id: '4',
        title: 'Color Trends 2024: Transform Your Space with These Popular Shades',
        excerpt: 'Stay ahead of the curve with the latest color trends for 2024. Discover which hues are set to transform interiors and how to incorporate them into your Simi Valley home for a fresh, modern look.',
        slug: 'color-trends-2024',
        tags: ['color trends', 'interior painting', 'home decor'],
        metaDescription: 'Embrace the newest color trends of 2024 with simivalleyhousepainting.com. Get inspired to refresh your home’s interior with the year’s most fashionable shades.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
]
